import AdobeConfig from 'src/config/adobe-config.json';
import { AppConfig } from './core/services/app-config.service';
import { AutoLogoutService } from './core/services/auto-logout.service';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { HeaderService } from './shared/services/header.service';
import { ResizeService } from './core/services/resize.service';
import { SCREEN_SIZE } from './core/enums/screen-size.enum';
import { SelectedTab } from '../app/shared/enums/request-page.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'CSClientApp';
  size!: SCREEN_SIZE;
  selectedTab = 0;
  landscapeWarning!: string;
  landscapeView: boolean = false;
  deviceInfo?: DeviceInfo;
  constructor(
    private deviceDetectorService: DeviceDetectorService,
    public headerService: HeaderService,
    private autoLogout: AutoLogoutService,
    private resizeSvc: ResizeService,
    private appConfig: AppConfig
  ) {
    this.resizeSvc.onResize$.subscribe((x) => {
      this.size = x;
      this.resizeSvc.setSize(this.size);
    });
    if (
      this.appConfig &&
      this.appConfig.config &&
      this.appConfig.config.LandscapeWarning
    ) {
      this.landscapeWarning = this.appConfig.config.LandscapeWarning;
    }
    this.loadScripts();
  }

  ngOnInit(): void {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
  }

  onActivate(event: any): void {
    window.scroll(0, 0);
    if (window.location.href.includes('home')) {
      this.selectedTab = SelectedTab.Home;
    } else if (window.location.href.includes('careerplan')) {
      this.selectedTab = SelectedTab.CareerPlan;
    } else if (window.location.href.includes('dashboard')) {
      this.selectedTab = SelectedTab.Dashboard;
    } else if (window.location.href.includes('report')) {
      this.selectedTab = SelectedTab.Report;
    } else if (window.location.href.includes('validation')) {
      this.selectedTab = SelectedTab.Validation;
    } else {
      this.selectedTab = SelectedTab.None;
    }
    this.headerService.tabChanged(this.selectedTab);
  }
  loadScripts() {
    const node = document.createElement('script');
    if (
      location.hostname.split('.')[0] == 'mycareersimulator-dev' ||
      location.hostname.split('.')[0] == 'mycareersimulator-build'
    )
      node.src = AdobeConfig[0]['Dev'];
    else if (
      location.hostname.split('.')[0] == 'mycareersimulator-stage' ||
      location.hostname.split('.')[0] == 'mycareersimulator-uat' ||
      location.hostname.split('.')[0] == 'mycareersimulator-pe'
    )
      node.src = AdobeConfig[0]['Stage'];
    else if (location.hostname.split('.')[0] == 'mycareersimulator')
      node.src = AdobeConfig[0]['Production'];
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
