<app-shared-common-modal #PolicyPopModal>
  <div class="bootstrap-modal-header" role="contentinfo">
    <div class="modal-header">
      <h3>Privacy Statement</h3>
      <button type="button" class="btn-close close" (click)="hidePolicyPopModal()" data-bs-dismiss="modal" aria-label="close"></button>
    </div>
  </div>
  <div class="bootstrap-modal-body" role="contentinfo">
    <div class="modal-body">
      <div class="container">
        <div class="card stv-container">
          <div class="card-body stv-card-body popup-desc">
            <p>Please be aware that any personal data that you and others provide through this tool may be processed by Accenture.</p>
            <p>
              The protection of your personal data is very important to Accenture. Accenture is committed to keeping your personal data secure, and processing it in accordance with, applicable data protection laws and our internal policies, including
              <a href="https://policies.accenture.com/policy/0090?rd=1&amp;Country=United%20Kingdom" rel="noopener" target="_blank" class="underline" title=" Accenture’s Global Data Privacy Policy 0090 (open new window)">
                <span class="underline_u">Accenture’s Global Data Privacy Policy 0090</span>
              </a>.
            </p><p>Before providing any personal data through this tool, Accenture invites you to carefully read its <a href="https://in.accenture.com/protectingaccenture/data-security/5422-2/" rel="noopener" target="_blank" class="underline" title="privacy statement (open new window)"><span class="underline_u">privacy statement</span></a>, which includes important information on why and how Accenture is processing your personal data.</p>
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="bootstrap-dialog-footer">
            <div class="bootstrap-dialog-footer-buttons">
              <div class="sbt-button"> <button class="btn btn-primary" data-dismiss="modal" (click)="hidePolicyPopModal()">Continue</button></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</app-shared-common-modal>









