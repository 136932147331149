import { ENV, envConfig } from './env-config';

const env = ENV.DEV;

export const environment = {
  production: true,
  env: env,
  peoplePicScope: envConfig[env].peoplePicScope,
  apiHost: envConfig[env].apiHost,
  assetsHost: envConfig[env].assetsHost,
  authUrl: envConfig[env].authUrl
};
