import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheDataEntry } from '../models/cached-data-entry.interface';

@Injectable()
export class RequestCacheService {

  private cacheData = new Map<string, CacheDataEntry>();

  constructor() { }

  clearCache() {
    this.cacheData = new Map<string, CacheDataEntry>();
  }

  get(req: HttpRequest<any>): HttpResponse<any> {
    let canClearCache: boolean = false;

    this.cacheData.forEach((value, key) => { if (value.expires <= new Date()) { canClearCache = true; } });

    if (canClearCache) {
      this.clearCache();
      return null as any;
    } else {
      const url: string = this.getRequestUrlForCache(req);
      const cachedData = this.cacheData.get(url);
      if (!cachedData) { return null as any; }

      if (cachedData.expires.getTime() < new Date().getTime()) {
        this.cacheData.delete(url);
        return null as any;
      }
      return cachedData.response;
    }
  }


  set(req: HttpRequest<any>, res: HttpResponse<any>, ttl: any) {
    const url: string = this.getRequestUrlForCache(req);
    const expires: Date = new Date();
    expires.setSeconds(expires.getSeconds() + ttl);

    const cacheDataEntry: CacheDataEntry = { response: res, expires: expires };
    this.cacheData.set(url, cacheDataEntry);
  }

  private getRequestUrlForCache(req: HttpRequest<any>): string {
    let requestUrlForCache: string;

    if (req.method === 'POST') {
      requestUrlForCache = req.urlWithParams + '{{' + (req.body ? JSON.stringify(req.body) : '') + '}}';
    }
    else {
        requestUrlForCache = req.urlWithParams;
    }
    return requestUrlForCache;
  }
}
