import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedTab } from '../../enums/request-page.enum';
import { HeaderService } from '../../services/header.service';
import { UserRepositoryService } from '../../../core/services/user-repository.service';
import { SCREEN_SIZE } from '../../../core/enums/screen-size.enum';
import { ResizeService } from '../../../core/services/resize.service';
import HeaderBanner from '../../../../../src/config/header-config.json';
import $ from "jquery";
import { UserDetails } from '../../../shared/models/userinfo-interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  userinfodetails: UserDetails;
  selectedTab: number = 2;
  isAdmin = false;
  enterpriseid: string = '';
  hideDashboardAndReport: boolean = false;
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', value: 1
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', value: 2
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', value: 2
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', value: 3
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', value: 3

    },
  ];

  navFixed: boolean = false;
  screenWidth: any = window.innerWidth;

  private scrollOffset: number = 70;
  constructor(private router: Router,
    private headerService: HeaderService,
    private userRepositoryService: UserRepositoryService,
    private resizeSvc: ResizeService) {
    this.userinfodetails = {
      EnterpriseId: '',
      IsAdmin: false,
      GivenName: '',
      FamilyName: ''
    }

  }
  @HostListener('window:scroll')
  onWindowScroll() {
    this.navFixed = (window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0
    ) > this.scrollOffset;
    if (this.selectedTab != SelectedTab.CareerPlan && this.selectedTab != SelectedTab.Dashboard && this.selectedTab != SelectedTab.Report && this.selectedTab != SelectedTab.Validation) {
      if ((this.screenWidth >= 769 && (window.pageYOffset - 50 < HeaderBanner[0]["VerticalScrollValue"] && HeaderBanner[0]["VerticalScrollValue"] < window.pageYOffset + 50))) {
        this.selectedTab = SelectedTab.OperationCareers;
      }
      else if ((this.screenWidth >= 769 && (window.pageYOffset - 50 < HeaderBanner[1]["VerticalScrollValue"] && HeaderBanner[1]["VerticalScrollValue"] < window.pageYOffset + 50))) {
        this.selectedTab = SelectedTab.SuccessStories;
      }
      else if ((this.screenWidth >= 769 && (window.pageYOffset - 50 < HeaderBanner[2]["VerticalScrollValue"] && HeaderBanner[2]["VerticalScrollValue"] < window.pageYOffset + 50))) {
        this.selectedTab = SelectedTab.OwnYourCareer;
      }
      else if ((this.screenWidth >= 769 && (window.pageYOffset - 50 < HeaderBanner[3]["VerticalScrollValue"] && HeaderBanner[3]["VerticalScrollValue"] < window.pageYOffset + 50))) {
        this.selectedTab = SelectedTab.FAQ;
      }
      else if ((this.screenWidth >= 769 && (window.pageYOffset < HeaderBanner[0]["VerticalScrollValue"] - 50))) {
        this.selectedTab = SelectedTab.Home;
      }
    }
  }
  ngOnInit(): void {

    if (this.screenWidth <= 1024) {
      this.hideDashboardAndReport = true;
    }
    this.headerService.selectedTab.subscribe((tab) => {
      if (tab != 0)
        this.selectedTab = tab;
    });

    this.userinfodetails = this.userRepositoryService.userInfo;
    this.isAdmin = this.userinfodetails.IsAdmin;
    this.enterpriseid = "T170335CS.001@ds.dev.accenture.com";
    //this.enterpriseid = this.userinfodetails.EnterpriseId.split('@')[0];   
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  public get selectedTabs(): typeof SelectedTab {
    return SelectedTab;
  }

  RedirectToCareerPlan(): void {
    this.selectedTab = SelectedTab.OperationCareers;
    if (this.screenWidth <= 480) {
      window.scroll(0, HeaderBanner[0]["VerticalScrollValueMobile"]);
    } else if (this.screenWidth <= 768 && this.screenWidth > 481) {
      window.scroll(0, HeaderBanner[0]["VerticalScrollValueIpad"]);
    } else {
      window.scroll(0, HeaderBanner[0]["VerticalScrollValue"]);
    }

  }

  RedirectToCareer() {
    this.router.navigate(['careerplan']);
  }

  RedirectToSucessStories(): void {
    this.selectedTab = SelectedTab.SuccessStories;
    if (this.screenWidth <= 480) {
      window.scroll(0, HeaderBanner[1]["VerticalScrollValueMobile"]);
    } else if (this.screenWidth <= 768 && this.screenWidth > 481) {
      window.scroll(0, HeaderBanner[1]["VerticalScrollValueIpad"]);
    } else {
      window.scroll(0, HeaderBanner[1]["VerticalScrollValue"]);
    }
  }
  RedirectToOwnYourCareer(): void {
    this.selectedTab = SelectedTab.OwnYourCareer;
    if (this.screenWidth <= 480) {
      window.scroll(0, HeaderBanner[2]["VerticalScrollValueMobile"]);
    } else if (this.screenWidth <= 768 && this.screenWidth > 481) {
      window.scroll(0, HeaderBanner[2]["VerticalScrollValueIpad"]);
    } else {
      window.scroll(0, HeaderBanner[2]["VerticalScrollValue"]);
    }
  }
  RedirectToFAQ(): void {
    this.selectedTab = SelectedTab.FAQ;
    if (this.screenWidth <= 480) {
      window.scroll(0, HeaderBanner[3]["VerticalScrollValueMobile"]);
    } else if (this.screenWidth <= 768 && this.screenWidth > 481) {
      window.scroll(0, HeaderBanner[3]["VerticalScrollValueIpad"]);
    } else {
      window.scroll(0, HeaderBanner[3]["VerticalScrollValue"]);
    }

  }
  RedirectToHome(): void {
    window.scroll(0, 0);
    $('#head').addClass('dashboard-header-unset');
    this.selectedTab = SelectedTab.Home;
    this.router.navigate(['home']);
  }
  RedirectToDashboard(): void {
    $('#head').addClass('dashboard-header');
    this.selectedTab = SelectedTab.Dashboard;
    this.router.navigate(['dashboard']);
  }
  RedirectToReport(): void {
    $('#head').addClass('dashboard-header');
    this.selectedTab = SelectedTab.Report;
    this.router.navigate(['report']);
  }
  RedirectToValidation(): void {
    $('#head').addClass('dashboard-header');
    this.selectedTab = SelectedTab.Validation;
    this.router.navigate(['validation']);
  }
  OnSkip() {
    $('.c-explore').focus();
  }


  detectScreenSize() {
    let currentSize: any;

    if (this.screenWidth <= 576) {
      if (this.sizes.find(x => x.id))
        currentSize = this.sizes.find(x => x.id == SCREEN_SIZE.XS)?.value;
    } else if (this.screenWidth <= 768 && this.screenWidth > 576) {
      currentSize = this.sizes.find(x => x.id == SCREEN_SIZE.SM)?.value;
    } else {
      currentSize = this.sizes.find(x => x.id == SCREEN_SIZE.LG)?.value;
    }

    this.resizeSvc.onResize(currentSize);
  }

  clicktologout() {
    localStorage.clear();
    localStorage.removeItem('lastAction');
    localStorage.removeItem('adobe_key');
    location.replace('home/SignOut');
  }

}
