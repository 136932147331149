import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HeaderService {
  public selectedTab = new BehaviorSubject<number>(0);
  public mainPageLoadFlag = new BehaviorSubject<boolean>(false);

  constructor() {}

  tabChanged(tab: number): void {
    this.selectedTab.next(tab);
  }

  setMainPageLoadFlag(load: boolean): void {
    setTimeout(() => {
      this.mainPageLoadFlag.next(load);
    }, 200);
  }
}
