export enum SelectedTab {
  None = 0,
  Home = 1,
  CareerPlan = 2,
  Dashboard = 3,
  Report = 4,
  OperationCareers = 5,
  SuccessStories = 6,
  OwnYourCareer = 7,
  FAQ = 8,
  Validation = 9
}
