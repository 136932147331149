<header id="head" [class.fixed]="navFixed">
  <div>
    <a href="javascript:void(0)" class="skip-main" (click)="OnSkip()" tabindex="0" (keydown.enter)="OnSkip()">Skip to main content</a>
  </div>
  <nav class="navbar navbar-dark bg-dark navbar-expand-sm background-pos-header">
    <div class="container-fluid">
      <a class="navbar-brand col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2" (keydown.enter)="RedirectToCareer()" (click)="RedirectToCareer()">
        <img src="assets/images/accenture-logo.webp" alt="img">
        <span tabindex="0">Accenture Song</span>
      </a>
      <div class="validation" *ngIf="isAdmin">
        <a class="nav-link" (click)="RedirectToValidation();" (keyup.enter)="RedirectToValidation();" tabindex="0">Validation</a>
      </div>
<!-- 
      <button *ngIf="selectedTab!=2" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarToggleExternalContent" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>

      </button>

      <div class="navbar-collapse collapse col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" id="navbarsExample03" *ngIf="isAdmin ">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0" *ngIf="selectedTab!=2">
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.Home?'active':'']">
            <a class="nav-link" (click)="RedirectToHome();" (keyup.enter)="RedirectToHome();" tabindex="0">Home</a>
          </li>
          <li class="nav-item" *ngIf="selectedTab!=2 && selectedTab!=3 && selectedTab!=4 && selectedTab!=9" [ngClass]="[selectedTab==selectedTabs.OperationCareers?'active':'']">
            <a class="nav-link" (click)="RedirectToCareerPlan();" (keyup.enter)="RedirectToCareerPlan();" tabindex="0">Operations Careers</a>
          </li>
          <li class="nav-item" *ngIf="selectedTab!=2 && selectedTab!=3 && selectedTab!=4 && selectedTab!=9" [ngClass]="[selectedTab==selectedTabs.SuccessStories?'active':'']">
            <a class="nav-link" (click)="RedirectToSucessStories();" (keyup.enter)="RedirectToSucessStories();" tabindex="0">Career Stories</a>
          </li>
          <li class="nav-item" *ngIf="selectedTab!=2 && selectedTab!=3 && selectedTab!=4 && selectedTab!=9" [ngClass]="[selectedTab==selectedTabs.OwnYourCareer?'active':'']">
            <a class="nav-link" (click)="RedirectToOwnYourCareer();" (keyup.enter)="RedirectToOwnYourCareer();" tabindex="0">Own your Career</a>
          </li>
          <li class="nav-item" *ngIf="selectedTab!=2 && selectedTab!=3 && selectedTab!=4 && selectedTab!=9" [ngClass]="[selectedTab==selectedTabs.FAQ?'active':'']">
            <a class="nav-link" (click)="RedirectToFAQ();" (keyup.enter)="RedirectToFAQ();" tabindex="0">FAQ</a>
          </li>

          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.Dashboard?'active':'']" *ngIf="selectedTab!=2 && !hideDashboardAndReport">
            <a class="nav-link" (click)="RedirectToDashboard();" (keyup.enter)="RedirectToDashboard();" tabindex="0">Dashboard</a>
          </li>

          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.Report?'active':'']" *ngIf="selectedTab!=2 && !hideDashboardAndReport">
            <a class="nav-link" (click)="RedirectToReport();" (keyup.enter)="RedirectToReport();" tabindex="0">Report</a>
          </li>
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.Validation?'active':'']" *ngIf="selectedTab!=2 && !hideDashboardAndReport">
            <a class="nav-link" (click)="RedirectToValidation();" (keyup.enter)="RedirectToValidation();" tabindex="0">Validation</a>
          </li>
        </ul>
      </div>
      <div class="navbar-collapse collapse col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" id="navbarsExample03" *ngIf="!isAdmin && selectedTab!=2">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0">
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.Home?'active':'']">
            <a class="nav-link" (click)="RedirectToHome();" (keyup.enter)="RedirectToHome();" tabindex="0">Home</a>
          </li>
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.OperationCareers?'active':'']">
            <a class="nav-link" (click)="RedirectToCareerPlan();" (keyup.enter)="RedirectToCareerPlan();" tabindex="0">Operations Careers</a>
          </li>
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.SuccessStories?'active':'']">
            <a class="nav-link" (click)="RedirectToSucessStories();" (keyup.enter)="RedirectToSucessStories();" tabindex="0">Career Stories</a>
          </li>
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.OwnYourCareer?'active':'']">
            <a class="nav-link" (click)="RedirectToOwnYourCareer();" (keyup.enter)="RedirectToOwnYourCareer();" tabindex="0">Own your Career</a>
          </li>
          <li class="nav-item" [ngClass]="[selectedTab==selectedTabs.FAQ?'active':'']">
            <a class="nav-link" (click)="RedirectToFAQ();" (keyup.enter)="RedirectToFAQ();" tabindex="0">FAQ</a>
          </li>
        </ul>
      </div> -->

      <!----------logout html ------------>
      <!--<div class="dropdown col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1 logout-width">
        <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/images/user-circle.svg" alt="img">
        </button>
        <ul class="dropdown-menu btn-lft logout-box" aria-labelledby="dropdownMenuButton1">
          <li class="dropdown-item logout-box-text">{{enterpriseid}}</li>
          <hr class="hr-op">
          <li>
            <img src="assets/images/drafting-compass.svg" class="logout-icn" alt="img">
            <a (click)="clicktologout();" class="dropdocwn-item logout-box-btm">Logout</a>
          </li>

        </ul>
      </div>-->

      <!-------------- logout ends -------------->
    </div>
  </nav>
</header>
