import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermPolicyComponent } from '../app/shared/components/term-policy/term-policy.component';
import { CoreModule } from '../app/core/core.module';
import { AppConfig } from './core/services/app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { SharedModule } from '../app/shared/shared.module';
import { HeaderComponent } from '../app/shared/components/header/header.component';
import { HeaderService } from '../app/shared/services/header.service';
import { FooterComponent } from '../app/shared/components/footer/footer.component';
import { CacheInterceptor } from '../app/core/services/cache-interceptor.service';
import { HttpRequestInterceptor } from '../app/core/services/http-request-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { AutoLogoutService } from '../app/core/services/auto-logout.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PolicyPopupComponent } from '../app/shared/components/policy-popup/policy-popup.component';
import { SharedCommonModalComponent } from '../app/shared/components/shared-common-modal/shared-common-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PolicyPopupComponent,
    SharedCommonModalComponent,
    TermPolicyComponent
  ],
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('access_token');
        }
    }}),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig], multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    AutoLogoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    HeaderService,
    DeviceDetectorService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
export function tokenGetter(){

  return localStorage.getItem('token');
 
 }