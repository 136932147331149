import { Component, OnInit, ViewChild } from '@angular/core';

import { AppConfig } from '../../../core/services/app-config.service';
import { FooterMessages } from '../../models/footer-messages.interface';
import { PolicyPopupComponent } from '../policy-popup/policy-popup.component';
import { TermPolicyComponent } from '../term-policy/term-policy.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @ViewChild('PolicyPopup', { static: false }) PolicyPopup!: PolicyPopupComponent;
  @ViewChild('TermPolicyPopup', { static: false }) TermPolicyPopup!: TermPolicyComponent;
  footerMessages: FooterMessages;
  currentYear: number = new Date().getFullYear();
  constructor(private appConfig: AppConfig) {
    this.footerMessages = {
      TermsOfUse: '',
      PrivacyStatement: '',
      ContactSupport: ''
    };
  }

  checkConfigLoaded(): boolean {
    return (this.appConfig && this.appConfig.getConfig() && this.appConfig.getConfig().Footer
      && this.appConfig.getConfig().Footer.TermsOfUse && this.appConfig.getConfig().Footer.PrivacyStatement
      && this.appConfig.getConfig().Footer.ContactSupport);
  }

  ngOnInit(): void {
    if (this.checkConfigLoaded()) {
      this.footerMessages.TermsOfUse = this.appConfig.getConfig().Footer.TermsOfUse;
      this.footerMessages.PrivacyStatement = this.appConfig.getConfig().Footer.PrivacyStatement;
      this.footerMessages.ContactSupport = this.appConfig.getConfig().Footer.ContactSupport;
    }
  }
  showPolicyPop() {
    this.PolicyPopup.showPolicyPopModal();
  }
  showTermPolicyPop() {
    this.TermPolicyPopup.showPolicyPopModal();
  }

}
