import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'careerplan'
  },
  {
    path: 'validation',
    loadChildren: () => import('./feature/validation/validation.module').then(m => m.ValidationModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./feature/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'careerplan',
    loadChildren: () => import('./feature/careerplan/careerplan.module').then(m => m.CareerPlanModule)
  },
  {
    path: '',
    loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: 'careerplan'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
