<footer class="footer">
  <nav class="navbar navbar-expand-lg border-top">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="assets\images\Acc_Logo_Black_Purple_RGB.svg" alt="img">
      </a>
      <span class="navbar-text text-nowrap">
        &copy; {{ currentYear }} Accenture. All Rights Reserved.
      </span>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a style="cursor:pointer" class="nav-link" tabindex="0"  (click)="showTermPolicyPop()" (keydown.enter)="showTermPolicyPop()">Terms of Use</a>
        </li>
        <li class="nav-item">
          <a style="cursor:pointer" class="nav-link" tabindex="0" (click)="showPolicyPop()" (keydown.enter)="showTermPolicyPop()">Privacy Statement</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" rel="noopener" target="_blank" [href]="footerMessages.ContactSupport">Contact Support</a>
        </li>
      </ul>
    </div>
  </nav>
</footer>
<app-policy-popup #PolicyPopup></app-policy-popup>
<app-term-policy #TermPolicyPopup></app-term-policy>
