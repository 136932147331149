export enum ENV {
  LOCAL = 'local',
  DEV = 'dev',
  TEST = 'test',
  STAGE = 'stage',
  PRO = 'production'

}

export const envConfig: {[envKey: string]: {peoplePicScope: string, apiHost: string, assetsHost: string, authUrl: string}} = {
  [ENV.LOCAL]: {
    peoplePicScope: '',
    apiHost: 'https://localhost:44348', // use back-end port here
    assetsHost: window.location.origin + '/' + window.location.pathname.split('/')[1] + '/',
    authUrl: ''
  },
  [ENV.DEV]: {
    peoplePicScope: '',
    apiHost: 'https://accenturesongcareerexplorer-api.ciodev.accenture.com',
    assetsHost: 'https://accenturesongcareerexplorer.ciodev.accenture.com/',
    authUrl: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22'
  },
  [ENV.TEST]: {
    peoplePicScope: '',
    apiHost: 'https://accenturesongcareerexplorer-api.ciotest.accenture.com',
    assetsHost: 'https://accenturesongcareerexplorer.ciotest.accenture.com/',
    authUrl: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22'
  },
  [ENV.STAGE]: {
    peoplePicScope: '4e9c8329-0ff3-4f7d-b357-8e4cf97e4cc2/50874.Api.ProfilePic',
    apiHost: 'https://accenturesongcareerexplorer-api.ciostage.accenture.com',
    assetsHost: 'https://accenturesongcareerexplorer.ciostage.accenture.com/',
    authUrl: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22'
  },
  [ENV.PRO]: {
    peoplePicScope: 'd5eab3a6-6223-49b9-8cbf-94452505ed31/50874.Api.ProfilePic',
    apiHost: 'https://accenturesongcareerexplorer-api.accenture.com',
    assetsHost: 'https://accenturesongcareerexplorer.accenture.com/',
    authUrl: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb'
  }
};
