import { EventType } from '@azure/msal-browser';
import { Inject, Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';

declare var globalMSALInstance: any;
@Injectable({
  providedIn: 'root'
  })

export class AuthenticationService implements OnInit {
  constructor() {}

  ngOnInit() { }

  public async GetUHAccessToken() {
    // set account info
    const account = globalMSALInstance.getAllAccounts()[0];

    const accessTokenRequest = {
      scopes: [environment.peoplePicScope],
      account: account
    };

    await globalMSALInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        const idToken = accessTokenResponse.idToken;
        localStorage.setItem('peoplePicIdToken', idToken);
        localStorage.setItem('peoplePicToken', accessToken);
      });
  }

  public async GetAccessToken() {
    // set account info
    const accountArr = globalMSALInstance.getAllAccounts();
    if (accountArr.length > 0) {
      globalMSALInstance.setActiveAccount(accountArr[0]);
    }
    const userInfo = globalMSALInstance.getActiveAccount();
    const authRequest = { correlationId: uuidv4(), authority: environment.authUrl };
    if (userInfo) {
      Object.assign(authRequest, { account: userInfo });
    }
    await globalMSALInstance.acquireTokenSilent(
      authRequest
    ).then((res: any) => {
      if (res) {
        localStorage.setItem('accessToken', res.accessToken);
      } else {
        console.log('no access token');
      }
    })
      .catch((err: any) => {
        console.log('err:', err);
      });
  }

  public CheckActiveAccount() {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = globalMSALInstance.getAllAccounts();
    if (accounts.length > 0) {
      globalMSALInstance.setActiveAccount(accounts[0]);
    }
    globalMSALInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        globalMSALInstance.setActiveAccount(account);
      }
    }, error => {
      console.log('error', error);
    });
    // handle auth redired/do all initial setup for msal
    globalMSALInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in
      const account = globalMSALInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        globalMSALInstance.loginRedirect();
      }
    }).catch(err => {
      // TODO: Handle errors
      console.log(err);
    });
  }

  public redirectLogin(authRequestConfig) {
    console.log('globalMSALInstance', globalMSALInstance);
    globalMSALInstance.loginRedirect(authRequestConfig);
  }
}
