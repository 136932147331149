import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SharedCommonModalComponent } from '../shared-common-modal/shared-common-modal.component'
@Component({
  selector: 'app-policy-popup',
  templateUrl: './policy-popup.component.html',
  styleUrls: ['./policy-popup.component.css']
})
export class PolicyPopupComponent implements OnInit {

  @ViewChild('PolicyPopModal', { static: false }) PolicyPopModal!: SharedCommonModalComponent;
  
  constructor() { }

  ngOnInit(): void {
   
  }
 
  hidePolicyPopModal() {
    this.PolicyPopModal.hide();
  }
  showPolicyPopModal() {
    this.PolicyPopModal.show();
  }
  
}
