import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appConfig } from 'src/config/appconfig';

@Injectable()
export class AppConfig {

  public config: any = null;
  appConfigUrl = "";
  private apiHost = "";
  constructor(
    private http: HttpClient) {
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(): any {
    if (this.config) {
      return this.config;
    }
  }

  public getSolutionBaseUrl(): string {
    this.apiHost = environment.apiHost;
    return this.apiHost;
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load(): Promise<any> {
   if(environment.env == "local"){
      this.appConfigUrl = "config/app-config.json"
    }else{
      this.appConfigUrl = environment.assetsHost + "config/app-config.json"
    }
    
    return new Promise((resolve, reject) => {
      this.http.get(this.appConfigUrl)
        .pipe(map(res => res))
        .pipe(catchError((error: any) => {
          return observableThrowError(error.json().error || 'Server error');
        }))
        .subscribe((configResponse: any) => {
          this.config = appConfig;
          resolve(true);
        });
    });
    
  }
}
