import { Injectable } from '@angular/core';
import { ErrorLog } from '../models/error-logging.interface';
import { AppConfig } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { UserInfoData } from 'src/app/core/services/user.config';

@Injectable()
export class ErrorLogService {
  serviceUrl = '';
  errorLog: ErrorLog;
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {
    this.errorLog = {
      ErrorCode: 0,
      MethodName: '',
      ErrorMessage: '',
      EnterpriseId: ''
    }
  }

  checkConfigLoaded(): boolean {
    return this.appConfig.getConfig() && this.appConfig.config.Logerror.Errorlog;  
  }

  errorlog(status: any, message: any, method: any) {
    this.errorLog.ErrorCode = status;
    this.errorLog.ErrorMessage = message;
    this.errorLog.MethodName = method;
    this.errorLog.EnterpriseId = UserInfoData.EnterpriseId;
    this.logerror(this.errorLog)
      .subscribe(
        (data: any) => {
        },
        (error: any) => {
        }
      );
  }

  logerror(errorLog: ErrorLog) {
    if (this.checkConfigLoaded()) {
      this.serviceUrl = this.appConfig.getSolutionBaseUrl() + this.appConfig.config.Logerror.Errorlog
    }
    return this.httpClient.post(this.serviceUrl, errorLog)
  }
} 




