<div role=dialog bsModal #bootstrapModal="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ignoreBackdropClick: true, backdrop:'static', keyboard:false }" style="overflow:auto">
  <div class="modal-dialog set-width">
    <div class="modal-content text-left fb-main-container" [ngClass]="cssClass">
      <ng-content select=".bootstrap-modal-header"></ng-content>
      <ng-content select=".bootstrap-modal-body"></ng-content>
      <ng-content select=".bootstrap-modal-footer"></ng-content>
    </div>
  </div>
</div>
