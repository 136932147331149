import { InjectionToken } from '@angular/core';
import { UserDetails } from '../../shared/models/userinfo-interface';

declare const window: any;
var eid = localStorage.getItem('EnterpriseId') ? localStorage.getItem('EnterpriseId') : "T205444";
var givenName = localStorage.getItem('GivenName');
var familyName = localStorage.getItem('FamilyName');
var isAdmin = (localStorage.getItem('IsAdmin') === "true") ? true : false;
export const UserInfoData: UserDetails = {
    EnterpriseId: eid,
    IsAdmin: isAdmin,
    GivenName: givenName,
    FamilyName: familyName
}
export const UserInfoToken = new InjectionToken<UserDetails>('UserDetails');