import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfig } from './app-config.service';
import { RequestCacheService } from './request-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private appConfig: AppConfig, private requestCacheService: RequestCacheService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let cachedResponse: HttpResponse<any>;

    if (this.isCachableRequest(req)) {
      cachedResponse = this.requestCacheService.get(req); 
      return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
    } else {
      return next.handle(req);
    }
  }

  private isCachableRequest(req: HttpRequest<any>) {
    let isCachingEnabled: boolean = false;
    let isNonCachableRequest: boolean = false;
    let isNonCachableAndClearCacheRequest: boolean = false;
    
    isCachingEnabled = (this.appConfig &&
      this.appConfig.config &&
      this.appConfig.config.ApplicationAngularCache &&
      this.appConfig.config.ApplicationAngularCache.Enabled);

    if (isCachingEnabled) {
      isNonCachableRequest = this.appConfig.config.ApplicationAngularCache.NonCachableRequests.some(function (i: any) {
        return (i.method.toLowerCase() === req.method.toLowerCase()
          && req.url.toLowerCase().indexOf(i.url.toLowerCase()) >= 0);
      });
      isNonCachableAndClearCacheRequest =
        this.appConfig.config.ApplicationAngularCache.NonCachableAndClearCacheRequests.some(function (i: any) {
          return (i.method.toLowerCase() === req.method.toLowerCase()
            && req.url.toLowerCase().indexOf(i.url.toLowerCase()) >= 0);
        });
      if (isNonCachableAndClearCacheRequest) {
        this.requestCacheService.clearCache();
      }      
    }
    return (isCachingEnabled && !isNonCachableRequest && !isNonCachableAndClearCacheRequest);
  }

  private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(res => {
        if (res instanceof HttpResponse && res.status === 200) {
          this.requestCacheService.set(req, res, this.appConfig.config.ApplicationAngularCache.CacheTimeToLive);
        }
      })
    );
  }
}
