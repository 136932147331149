import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedCommonModalComponent } from '../shared-common-modal/shared-common-modal.component'
@Component({
  selector: 'app-term-policy',
  templateUrl: './term-policy.component.html',
  styleUrls: ['./term-policy.component.css']
})
export class TermPolicyComponent implements OnInit {
  @ViewChild('TermPolicyPopModal', { static: false }) TermPolicyPopModal!: SharedCommonModalComponent;
  constructor() { }

  ngOnInit(): void {
  }
  hidePolicyPopModal() {
    this.TermPolicyPopModal.hide();
  }
  showPolicyPopModal() {
    this.TermPolicyPopModal.show();
  }
}
