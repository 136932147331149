import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { UserRepositoryService } from './user-repository.service';


@Injectable()
export class CommonAuthGuardService  {

  constructor(private userRepositoryService: UserRepositoryService,
    private router: Router) { }

  canActivate(): boolean {
    const isAdmin = this.userRepositoryService.userInfo;
    if (!isAdmin) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
