import { AppConfig } from './app-config.service';
import { AuthenticationService } from './authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  scopes = [];
  constructor(private readonly _authenticationService: AuthenticationService, private readonly jwtHelperService: JwtHelperService, private readonly appConfig: AppConfig
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    switch (environment.env) {
      case 'dev':
        this.scopes = ['864fa754-d29d-4c84-a46e-0c1d423a4812/read_songcareerexplorer',
          '864fa754-d29d-4c84-a46e-0c1d423a4812/write_songcareerexplorer'];
        break;
      case 'test':
        this.scopes = ['864fa754-d29d-4c84-a46e-0c1d423a4812/read_songcareerexplorer',
        '864fa754-d29d-4c84-a46e-0c1d423a4812/write_songcareerexplorer'];
      break;
      case 'stage':
        this.scopes = ['283bccba-12ef-4aff-9182-de3e0ef57726/read_songcareerexplorer',
          '283bccba-12ef-4aff-9182-de3e0ef57726/write_songcareerexplorer'];
        break;
      case 'production':
        this.scopes = ['api://c1285ae3-a5a5-48dc-8b1c-d02a19fa0b61/read_songcareerexplorer',
          'api://c1285ae3-a5a5-48dc-8b1c-d02a19fa0b61/write_songcareerexplorer'];
        break;
      default:
        break;
    }

    if (environment.env !== 'local') {
      this._authenticationService.GetAccessToken();
      // get token from eso then use token to fetch people pic from API
      this._authenticationService.CheckActiveAccount();
      const accessToken: any = localStorage.getItem('accessToken');
      const idToken: any = localStorage.getItem('idToken');
      const idTokenExpired = this.jwtHelperService.isTokenExpired(idToken);
      const accessTokenExpired = this.jwtHelperService.isTokenExpired(accessToken);
      const authRequestConfig = {
        scopes: this.scopes
      };
      if (idTokenExpired || accessTokenExpired) {
        this._authenticationService.redirectLogin(authRequestConfig);
      }
      switch (environment.env) {
        case 'dev':
          req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + accessToken) });
          break;
        case 'test':
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + accessToken) });
            break;
        case 'stage':
          req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + idToken) });
          break;
        case 'production':
          req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + idToken) });
          break;
        default:
          break;
      }
    }
    return next.handle(req);
  }
}
