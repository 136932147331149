import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppConfig } from '../core/services/app-config.service';
import { RequestCacheService } from '../core/services/request-cache.service';
import { UserInfoToken, UserInfoData } from './services/user.config';
import { UserRepositoryService } from './services/user-repository.service';
import { CommonAuthGuardService } from '../core/services/common-authguard.service';
import { ResizeService } from '../core/services/resize.service';
import {ErrorLogService} from '../core/services/error-log.service';




@NgModule({
    imports: [
      CommonModule
    ],
  declarations: [
    
  ],
  providers: [
    AppConfig,    
    RequestCacheService,
    {
      provide: UserInfoToken,
      useValue: UserInfoData
    },
    UserRepositoryService,
    ResizeService,
    CommonAuthGuardService,
    ErrorLogService

  ]
})
export class CoreModule { }
