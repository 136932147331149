export const appConfig = {
    "Organizations": {
      "GetOrganizations": "/api/Organization/GetAllOrganizations"
    },
    "Offerings": {
      "GetOfferings": "/api/Offering/GetOfferingsBasedOnOrganisation?OrgId="
  
    },
    "AreaOfInterest": {
      "GetAreasOfInterest": "/api/Offering/GetOfferings",
      "GetOtherBusinessOfferings": "/api/Offering/GetOtherBusinessOfferings?OfferingId=",
      "GetOfferingForSelectOrg": "/api/Offering/GetOfferingforSelectOrganisation",
      "GetOtherEnablementRoles": "/api/Organization/GetOtherEnablementRoles?OrgId=",
      "OfferingId": "&OfferingId=",
      "SubOfferingId": "&SubOfferingId="
    },
    "SubOfferings": {
      "GetSubOfferings": "/api/SubOffering/GetSubOfferingsByOffering?OfferingId=",
      "RoleId": "&RoleId="
    },
    "CareerLevel": {
      "GetCLBasedonOfferingandRole": "/api/CareerLevel/GetCLByOfferingandRole?OfferingId=",
      "RoleId": "&RoleId=",
      "SubOfferingId": "&SubOfferingId="
    },
    "JobProfile": {
      "GetJobProfiles": "/api/JobProfile/GetJobProfilesByCLAndSubOffering?CLId=",
      "SubOffering": "&SubOfferingId=",
      "GetAspiringJobProfiles": "/api/JobProfile/GetJobProfilesByCLAndOffering",
      "Offering": "&OfferingId=",
      "GetALLJobProfiles": "/api/jobprofile/GetAllJobProfiles"
    },
    "GetCareerPath": {
      "GetCareerPathId": "/api/Feedback/GetCareerPath?currentRoleID="
    },
    "UpdateRatingFeedback": {
      "UpdateFeedback": "/api/Feedback/UpdateFeedback"
    },
    "Logerror": {
      "Errorlog": "/api/ErrorLog/UpdateErrorLog"
    },
  
    "Skills": {
      "GetSkillsBasedOnJobProfile": "/api/Skill/GetSkillsByJobProfile?JobProfileId="
    },
    "Roles": {
      "GetRoles": "/api/Role/GetRolesByOffering?OfferingId="
    },
    "Interview": {
      "GetInterviewBasedOnJobProfile": "/api/interview/GetInterviewByJobProfile?SubOfferingId=",
      "PageSizeForInterviews": 3
    },
    "Avatars": {
      "GetAvatarForCurrentUser": "/api/Avatar/GetAvatarForCurrentUser",
      "GetAllAvatars": "/api/Avatar/GetAllAvatars",
      "DefaultAvatar": {
        "AvatarId": 1,
        "AvatarSource": "assets/images/avatars/avatar1.png"
      }
    },
    "Users": {
      "AddUpdateUser": "/api/user/AddUpdateUser"
    },
    "CareerJourneys": {
      "AddCareerJourney": "/api/CareerJourney/AddCareerJourney",
      "GetCareerJourney": "/api/CareerJourney/GetCareerJourney",
      "ApproveCareerJourney": "/api/CareerJourney/ApproveCareerJourney",
      "DeleteCareerJourney": "/api/CareerJourney/DeleteCareerJourney",
      "NoPersonFoundMessage": "We cannot find the job role which you have chosen. Please try again."
    },
    "SuccessStoryLink": {
      "AddSuccessStoryLink": "/api/ShareSuccessStoryLink/AddSuccessStoryLink",
      "GetAllPendingSuccessStoryLink": "/api/ShareSuccessStoryLink/GetAllPendingSuccessStoryLink",
      "ApprovePendingSuccessStoryLink": "/api/ShareSuccessStoryLink/ApprovePendingSuccessStoryLink",
      "RejectPendingSuccessStoryLink": "/api/ShareSuccessStoryLink/RejectPendingSuccessStoryLink",
      "GetSuccessStoryLinks": "/api/ShareSuccessStoryLink/GetAllApprovedSuccessStoryLinks"
    },
  
    "DownloadHtmlTemplatePath": "dist/DownloadTemplate.html",
    "Footer": {
      "TermsOfUse": "https://portal.accenture.com/#/mysubscriptions-videos",
      "PrivacyStatement": "https://portal.accenture.com/#/mysubscriptions-videos",
      "ContactSupport": "https://support.accenture.com/support_portal?id=main&spa=1&tab=home-page&catalog_sys_id=e0d08b13c3330100c8b837659bba8fb4"
    },
    "PossibleCareerOptions": {
      "CardTitle1": "Jobs by area of expertise",
      "CardTitle2": "Jobs at current management level and next management level",
      "CardContent1": "You can explore jobs within your current business offering, know more about Operations enablement jobs as well as other business offerings.",
      "CardContent2": "You can look at career options at the current level or next career level."
    },
  
    "Faqs": [
      {
        "FaqID": 1,
        "Question": "What is use of Operations Careers?",
        "Answer": "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow."
      },
      {
        "FaqID": 2,
        "Question": "How to use Operations Careers?",
        "Answer": "An employee needs to enter his/her current role details and select Area of interest to explore career options at the same career level and next career level within or outside his industry or offering.The employee can select the desired role and study the expected job responsibilities, and skills. He can also acquire expected skills through recommended learning programs to plan his career movement for that role."
      },
      {
        "FaqID": 3,
        "Question": "What is not involved in Operations Careers?",
        "Answer": "This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow."
      }
    ],
  
    "SpecialRole": "People Leader",
    "SpecialOffering": "Across Offerings",
    "LongTermCareerRoadMapButtonDisablementRoleOne": "5",
    "LongTermCareerRoadMapButtonDisablementRoleTwo": "4",
    "DefaultImageUrl": "assets\\images\\bootstrap-solid.svg",
    "DefaultProfile": "assets/images/default-profile.jpg",
    "CareerRole": "Managing Director",
    "MangingDirectorCareerLevel": "4",
    "CareerPaths": {
      "AddCareerPath": "/api/CareerPath/AddCareerPath"
    },
    "FeedbackSteps": [
      {
        "Step": "Update your resume and career profile in workday (including skill)"
      },
      {
        "Step": "Meet your Manager(Business leader), people lead, or HR contact to talk about career progression"
      },
      {
        "Step": "Read Transfer policy 500, so you know what to expect if you move job internally"
      },
      {
        "Step": "Get some interview preparation done now"
      },
      {
        "Step": "Search jobs and tailor job alerts to be delivered to your inbox at career market place in work day"
      }
    ],
    "ApplicationAngularCache": {
      "Enabled": true,
      "CacheTimeToLive": 300,
      "NonCachableRequests": [
        {
          "method": "POST",
          "url": "JobProfile/GetJobProfilesByCLAndOffering"
        }
  
      ],
      "NonCachableAndClearCacheRequests": [
        {
          "method": "GET",
          "url": "interview/GetInterviewByJobProfile"
        },
        {
          "method": "GET",
          "url": "Offering/GetOfferingsBasedOnOrganisation"
        },
        {
          "method": "POST",
          "url": "User/AddUpdateUser"
        },
        {
          "method": "POST",
          "url": "CareerPath/AddCareerPath"
        },
        {
          "method": "POST",
          "url": "CareerJourney/ApproveCareerJourney"
        },
        {
          "method": "GET",
          "url": "CareerJourney/GetCareerJourney"
        },
        {
          "method": "POST",
          "url": "CareerJourney/DeleteCareerJourney"
        },
        {
          "method": "GET",
          "url": "ShareSuccessStoryLink/GetAllPendingSuccessStoryLink"
        }
      ]
    },
    "noCarrerOptionsAvailableMessage": "No career options to show",
    "AreaOfInterestScroll": 2500,
    "AutoLogout": {
      "MinsUntilLogout": 20,
      "CheckInterval": 90000,
      "StoreKey": "lastAction",
      "LogoutEnabled": true
    },
    "WorkdayUrl": "https://wd3.myworkday.com/accenture/d/home.htmld",
    "LandscapeWarning": "Please shift back to portrait view",
    "RateUsText": "Let us know what you think of the Song Career Explorer and what other features would be helpful to you."
  }
  