import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SCREEN_SIZE } from '../enums/screen-size.enum';

@Injectable()
export class ResizeService {

  screenSize!: SCREEN_SIZE;

  get onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable();
  }

  private resizeSubject: Subject<SCREEN_SIZE>;

  constructor() {
    
    this.resizeSubject = new Subject();
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }
  setSize(size: SCREEN_SIZE) {
    this.screenSize = size;

  }
}
