import { Injectable, Inject } from '@angular/core';
import { UserInfoToken } from './user.config';
import { UserDetails } from '../../shared/models/userinfo-interface';

@Injectable()
export class UserRepositoryService {


    public userInfo: UserDetails;

  constructor(@Inject(UserInfoToken) userInfo: UserDetails)
   {
    this.userInfo = userInfo;
}


}
