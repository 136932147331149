import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shared-common-modal',
  templateUrl: './shared-common-modal.component.html',
  styleUrls: ['./shared-common-modal.component.css']
})
export class SharedCommonModalComponent implements OnInit {

  @ViewChild('bootstrapModal', { static: false }) bootstrapModal!: ModalDirective
  @Input() modalType?: string;
  cssClass: string = '';

  constructor() {
  }

  ngOnInit(): void {
   
  }

  show() {
    this.bootstrapModal.show();
  }

  hide() {
    this.bootstrapModal.hide();
  }

}
