import { Injectable, NgZone, Inject } from '@angular/core';
import { AppConfig } from './app-config.service';

@Injectable()
export class AutoLogoutService {
  minsUntilAutoLogout: number=0; // in mins
  checkInterval: number= 0; // in ms
  storeKey: string='';
  logoutEnabled: boolean = false;

  // method to get the last action time from localstorage
  public getLastAction() {
    return parseInt(localStorage.getItem(this.storeKey)!, 10);
  }

  // method to set the action time in localstorage
  public setLastAction(lastAction: number) {
    localStorage.setItem(this.storeKey, Date.now().toString());
  }

  constructor(private _appConfig: AppConfig,
    private ngZone: NgZone) {
    this.loadConfigDetails();
    if (this.logoutEnabled) {
      localStorage.removeItem('lastAction');
      this.check();
      this.initListener();
      this.initInterval();
      localStorage.setItem(this.storeKey, Date.now().toString());

    }
  }

  // method to load configurable values from json
  loadConfigDetails() {
    if (this._appConfig && this._appConfig.config && this._appConfig.config.AutoLogout) {
      this.storeKey = this._appConfig.config.AutoLogout.StoreKey;
      this.checkInterval = this._appConfig.config.AutoLogout.CheckInterval;
      this.minsUntilAutoLogout = this._appConfig.config.AutoLogout.MinsUntilLogout;
      this.logoutEnabled = this._appConfig.config.AutoLogout.LogoutEnabled;
    }
  }

  // method to add time for signout if user clicks on anywhere in the page
  initListener() {
    if (this.logoutEnabled) {
      this.ngZone.runOutsideAngular(() => {
        document.body.addEventListener('click', () => this.reset());
      });
    }
  }

  // add time for signout
  reset() {
    if (this.logoutEnabled) {
      this.setLastAction(Date.now());
    }
  }

  // check whether to signout or not in every interval time set in config
  initInterval() {
    if (this.logoutEnabled) {
      this.ngZone.runOutsideAngular(() => {
        setInterval(() => {
          this.check();
        }, this.checkInterval);
      });
    }
  }

  // calculate whether to sign out or not
  check() {
    if (this.logoutEnabled) {
      const now = Date.now();
      const timeleft = this.getLastAction() + this.minsUntilAutoLogout * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;
      this.ngZone.run(() => {        
        if (isTimeout) {
          localStorage.clear();
          localStorage.removeItem('lastAction');
          localStorage.removeItem('adobe_key');
          location.replace('home/SignOut');
        }
      });
    }
  }
}
